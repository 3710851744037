<template>
  <div class="spinner-center">
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <div v-if="isShow" role="status">
        <div class="highlightRoundBox">
          <span class="loading">
            <span class="edge edge-left"></span>
            <span class="edge edge-right"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style scoped>
.style-spinner {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100vh;
  width: 100vw;
}
.spinner-center {
  height: 100vh;
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  max-height: 100vh;
  width: 100vw;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.highlightRoundBox {
  position: relative;
  width: 30px;
  height: 30px;
}

.loading {
  display: block;
  position: relative;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  border: 5px solid #0050f0;
  border-top-color: #e4edfe;
  animation: spin 2s infinite linear;
}

.loading .edge {
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  background: #0050f0;
}
.loading .edge.edge-left {
  top: -1.5px;
  left: -1px;
}
.loading .edge.edge-right {
  top: -1.5px;
  right: -1px;
}
</style>
